/* font-family: 'DM Serif Display', serif;
font-family: 'EB Garamond', serif;
font-family: 'Kanit', sans-serif;
font-family: 'Parisienne', cursive;
font-family: 'Rubik Mono One', sans-serif;
font-family: 'Wallpoet', cursive;
font-family: 'Work Sans', sans-serif; */

.navbar {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0px
}

#about .navbar, #services .navbar{
    background-color: #171717;
}

.navbar-brand {
    font-family: 'Wallpoet', sans-serif;
    color: #fd9116;
    font-size: 35px;
    transition-duration: .5s;
}

.navbar-brand:hover {
    color: #ffa33a;
}

.nav-link {
    color: white;
    font-family: 'Kanit', sans-serif;
    font-size: 20px;
    font-weight: 100;
    transition-duration: .5s;
}

.nav-link:hover {
    color: rgba(255, 255, 255, 0.6);
}

.navbar-toggler {
    color: white;
    border-color: transparent;
}

@media (max-width: 768px) {
    .navbar-brand {
        font-size: 25px
    }
}

@media (max-width: 550px) {
    .navbar-brand {
        font-size: 20px
    }
}